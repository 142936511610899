//Polyfill
import "core-js";

//Can't resolve 'regenerator-runtime/runtime'が表示されたとき(async awaitを使う場合など)
//import  regeneratorRuntime  from  "regenerator-runtime" ;

//bootstrapのmodalを読み込み
import "/node_modules/bootstrap/js/dist/modal";

//jqueryの読み込み
import $ from "jquery";
import ModalVideo from "modal-video"

//scroll_effect ふわっとする表現
$(window).scroll(function () {
    var scrollAnimationElm = document.querySelectorAll('.scroll_up , .scroll_left , .scroll_right');
    var scrollAnimationFunc = function () {
        for (var i = 0; i < scrollAnimationElm.length; i++) {
            var triggerMargin = 150;
            if (window.innerHeight > scrollAnimationElm[i].getBoundingClientRect().top + triggerMargin) {
                scrollAnimationElm[i].classList.add('on');
            }
        }
    }
    window.addEventListener('load', scrollAnimationFunc);
    window.addEventListener('scroll', scrollAnimationFunc);
});

new ModalVideo('.video-btn');

//swiper
const swiper = new Swiper(".mySwiper", {
    loop: true,
    speed: 800,
    slidesPerView: 1.3,
    pagination: {
        el: ".swiper-pagination",
        clickable: true,
    },
    centeredSlides : true
});